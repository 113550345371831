import React, { useState } from 'react';
import useAuth from '../../../utils/useAuth';
import './rewards.css';
import imageIcon from '../../../assets/rewards/image-icon.png';
import videoIcon from '../../../assets/rewards/video-icon.png';
import textIcon from '../../../assets/rewards/text-icon.png';
import questionMark from '../../../assets/rewards/question-mark-icon.png';
import present from '../../../assets/rewards/gift-icon.png';
import audio from '../../../assets/rewards/audio-icon.png';
import flameIcon from '../../../assets/rewards/flame-icon.png';
import levelEnd from '../../../assets/rewards/level-end.png';
import check from '../../../assets/check.svg';
import { useUserProgress } from '../../../state/use-user-progress';
import UserProgressHeaderStatic from '../../header/user-progress-header/user-progress-header-static';
import BackButton from '../../utils/back-button/back-button';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import { useNavigate } from 'react-router-dom';
import { RewardStage, RewardLevel } from './reward-models';
import BearRewards from '../bear-rewards/bear-rewards';

const Rewards: React.FC = () => {
    useAuth();
    const { userProgress, levels } = useUserProgress();
    const navigate = useNavigate();
    const [shakingIndex, setShakingIndex] = useState<number | null>(null);
    const [ui, setUi] = useState<string>('');

    function handleRewardClick(reward: RewardStage, isUnlocked: boolean, index: number): void {
        if(isUnlocked && !reward.burned) {
            navigate(`/rewards/level${reward.level}-stage${reward.stage}`, {state: reward});
        } else {
            setShakingIndex(index);
            setTimeout(() => setShakingIndex(null), 500);
        }
    }

    function mapRewardTypeToIcon(type: RewardStage['type']): string {
        switch (type) {
            case 'image':
                return imageIcon;
            case 'video':
                return videoIcon;
            case 'video-file':
                return videoIcon;
            case 'text':
                return textIcon;
            case 'link':
                return textIcon;
            case 'audio':
                return audio;
            case 'secret':
                return questionMark;
            case 'hot':
                return flameIcon;
            case 'hot-video':
                return flameIcon;
            case 'levelEnd':
                return levelEnd;
            case 'levelThreeEnd':
                return flameIcon;
            case 'present':
                return present;
            default:
                return imageIcon;
        }
    }

    function mapRewardLevelsToElements(rewards: Array<RewardLevel>): JSX.Element[] {
        return rewards.map((rewardLevel, index) => {
            return (
                <div key={index} className="flex flex-col items-center w-full mb-8 last:mb-0">
                    <h2 className={`flex justify-center items-center w-11/12 h-12 mb-8 mr-5 ml-5 text-2xl font-bold text-white bg-jam-glass rounded-3xl ${ userProgress.level < rewardLevel.level && 'shadow-dark-layer'}`}>
                        Level {rewardLevel.level} - {rewardLevel.levelName}
                    </h2>
                    <div className="flex flex-row justify-center items-center">
                        { mapRewardStagesToElements(rewardLevel.stages) }
                    </div>
                </div>
            );
        });
    }

    function mapRewardStagesToElements(stages: Array<RewardStage>): JSX.Element {
        return (
            <div className="flex flex-col w-[20.125rem] z-10">
                {
                    stages.map((reward, index) => {
                        const isUnlocked = reward.level < userProgress.level || (reward.level <= userProgress.level && reward.stage <= userProgress.stage);
                        const icon = mapRewardTypeToIcon(reward.type);
                        const cursorPointer = isUnlocked ? 'cursor-pointer' : '';
                        const shakeAnimation = shakingIndex === index ? 'animate-shake' : '';

                        return (
                            <div key={index} onClick={() => handleRewardClick(reward, isUnlocked, index)} className="item relative flex justify-center w-[5.375rem] h-[5.375rem] last:mb-[2.5rem]">
                                <div className={`z-10 relative flex items-center justify-center bg-home-tile w-[5.375rem] h-[5.375rem] rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md ${cursorPointer}`}>
                                    { !isUnlocked && <div className="absolute flex flex-col items-center w-[5.375rem] h-[5.375rem] bg-black bg-opacity-60 rounded-[1.25rem]"></div> }
                                    <img src={icon} alt="reward" className={`w-[4rem] ${shakeAnimation}`}/>
                                </div>
                                <div className={`z-0 absolute flex justify-center items-end bottom-0 h-16 w-2/3 mb-[-1.25rem] text-white font-bold rounded-b-[1.25rem] ${reward.burned ? 'bg-yellow-700' : 'bg-green-700'}`}>
                                    { !isUnlocked && <div className="absolute flex flex-col items-center w-full h-[5.375rem] bg-black bg-opacity-60 rounded-[1.25rem]"></div> }
                                    <div className="flex gap-1">{reward.stage} { reward.burned && <img src={check} alt="checkmark" /> }</div>
                                </div>
                            </div>
                        );
                    })
                }           
            </div>
        )
    }

    const mausRewards = (
        <div className="bg-gray-800">
            <ScrollingHeader className="z-30">
                <BackButton className="bg-black"/>
                <UserProgressHeaderStatic />
            </ScrollingHeader>
            <div className="pt-48">
                { mapRewardLevelsToElements(levels) }
            </div>
        </div>
    );

    const bearRewards = (
        <BearRewards />
    );

    const handleRewardsUi = (ui: string) => {
        setUi(ui);
    }

    return (
        <>
            {
                ui === '' && (
                    <>
                        <BackButton className="absolute"/>
                        <div className="flex flex-col gap-4 h-screen justify-center items-center bg-gray-800">
                            <div
                                className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                                onClick={() => handleRewardsUi('maus')}
                            >
                                <span className="text-[5rem]">🐭</span>
                                <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Mäuschen</span>
                            </div>
                            <div
                                className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                                onClick={() => navigate('/bear-rewards')}
                            >
                                <span className="text-[5rem]">🐻</span>
                                <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Bärchen</span>
                            </div>
                        </div>
                    </>
                )
            }
            {
                ui === 'maus' && mausRewards
            }
            {
                ui === 'bear' && bearRewards
            }
        </>
    );
};

export default Rewards;