import React, { useState } from 'react';
import './present.css';

const LevelOneEnd: React.FC = () => {
    const [step, setStep] = useState<number>(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const images = {
        image1: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5008-min.png?alt=media&token=d3fef048-08ce-4164-b416-87fd1acfc688',
        image2: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5016-min.png?alt=media&token=29c1da3e-c139-4b5d-86dc-659d774caf0f',
        image3: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5017-min.png?alt=media&token=eec1453a-5cc8-4574-bb77-fb0c5b89e3c5',
        image4: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5018-min.png?alt=media&token=2326f3bb-eb58-46c8-ba71-f128b3885d5f',
        image5: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5019-min.png?alt=media&token=38094fc1-9438-4b13-b9a8-178797013578',
        image6: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5020-min.png?alt=media&token=13d6148c-1a30-49dd-b06f-f87871f97d76',
        image7: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5021-min.png?alt=media&token=f81611ac-9d37-4fa3-9c63-07ca42a17718',
        image8: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5022-min.png?alt=media&token=da8b3dc3-5215-4681-ad44-9a63305055ba',
        image9: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5023-min.png?alt=media&token=dacd18d1-958d-48cb-b23a-cd33866b6bc3',
        image10: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5027-min.png?alt=media&token=08bdcb4a-e66b-411b-bc8e-3f805bab76ab',
        image11: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5032-min.png?alt=media&token=95fd0569-a08e-460d-be5c-37a91bbc0643',
        image12: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5033-min.png?alt=media&token=912ec93a-5f59-47e3-acc9-7b925a0c981c',
        image13: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-1-end%2FIMG_5035-min.png?alt=media&token=daac243c-9d37-4704-8217-2ed88f535b55',
    }

    return (
        <div className="flex flex-col items-center present text-center pb-10 m-[-1rem] p-4 bg-slate-900">
            <h1>Herzlichen Glückwunsch Maus ❤️!</h1>
            <p>Du hast Level 1 beendet.</p>
            <p>Das hier ist deine finale Belohnung.</p>
            <p className="mb-4">Du erinnerst dich and die Suche nach dem verlorenen Knutschi in Stage 4 (Knutschi von BärOnAir). Hier ist das Ende der Geschichte.</p>
            <div className="flex flex-col items-center">
                {step >= 1 && (
                    <img src={images.image1} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 2 && (
                    <div className="flex flex-col items-center my-4">
                        <h2 className="text-2xl font-bold">Der Verlorene Knutschi</h2>
                        <p>Alle Bilder gibts in guter Qualität im <a href="https://drive.google.com/drive/folders/1jNvX2W282R5BL-BfZAE34ciLWj1j1n2u?usp=drive_link" className="text-blue-400" onClick={nextStep}>Google Drive</a></p>
                        <img src={images.image2} className="w-[20rem] pointer-events-none" />
                    </div>
                )}
                {step >= 3 && (
                    <img src={images.image3} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 4 && (
                    <img src={images.image4} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 5 && (
                    <img src={images.image5} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 6 && (
                    <img src={images.image6} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 7 && (
                    <img src={images.image7} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 8 && (
                    <img src={images.image8} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 9 && (
                    <img src={images.image9} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 10 && (
                    <img src={images.image10} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 11 && (
                    <div className="flex flex-col items-center my-4">
                        <h2 className="text-2xl font-bold">Ein Gruß vom Cast:</h2>
                        <p>Jesus Jr., Flick, Brooko und dein Gemeinbär</p>
                        <img src={images.image11} className="w-[20rem] pointer-events-none" />
                    </div>
                )}
                {step >= 12 && (
                    <img src={images.image12} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 13 && (
                    <div className="flex flex-col items-center my-4">
                        <h2 className="text-2xl font-bold">Sneak</h2>
                        <p>Ohh! Hier war wohl auch ein Ärgerbär unterwegs! Was der wohl alles gemacht hat.</p>
                        <img src={images.image13} className="blur-[2px] w-[20rem] pointer-events-none" />
                    </div>
                )}
            </div>
            {step <= 12 && (
                <button className="w-48 p-2 mx-8 mt-4 font-home font-bold text-1xl bg-jam-glass border-1 border-white rounded-xl shadow-2xl" onClick={nextStep}>Weiter {`(${step + 1}/13)`}</button>
            )}
        </div>
    );
};

export default LevelOneEnd;