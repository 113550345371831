import React, { useEffect, useState } from 'react';
import { useUserProgress } from '../../../state/use-user-progress';


interface UserProgressHeaderStaticProps {
    className?: string;
}

function calculateStageProgress(userXP: number, xpNeededPerStage: number): number {
  const progressInStage = userXP % xpNeededPerStage;
  const percentage = (progressInStage / xpNeededPerStage) * 100;
  return Math.floor(percentage);
}

const UserProgressHeaderStatic: React.FC<UserProgressHeaderStaticProps> = ({ className = '' }) => {
  const { userProgress, xpNeededPerStage } = useUserProgress();
  const [stageProgress, setStageProgress] = useState<number>(0);

  useEffect(() => {
    const progress = calculateStageProgress(userProgress.xp, xpNeededPerStage);
    setStageProgress(progress);
  }, [userProgress.xp, xpNeededPerStage]);

  return (
    <div className={`z-30 flex flex-col w-full p-4  text-white font-bold bg-gray-900 shadow-gray-900 shadow-md ${className}`}>
        <div className="grid grid-cols-4 w-full text-2xl tracking-widest">
            <span>Level</span>
            <span>{userProgress.level}</span>
            <span>Stufe</span>
            <span>{userProgress.stage}</span>
        </div>
        <div>
          <div className="mt-2 mb-1 text-base font-medium text-green-700 dark:text-green-500">Fortschritt zu Stufe {userProgress.stage + 1}</div>
          <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
              <div className="bg-green-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${stageProgress}%` }}>{stageProgress}%</div>
          </div>
        </div>
    </div>
  );
};

export default UserProgressHeaderStatic;
