import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db, auth } from '../../../firebaseconfig';
import { getEnvKey } from '../../../utils/getEnvKey';
import { useNavigate } from 'react-router-dom';
import { useUserProgress } from '../../../state/use-user-progress';
import Modal from '../../utils/modal';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import BackButton from '../../utils/back-button/back-button';

const BearMailForm: React.FC = () => {
  const [message, setMessage] = useState('');
  const [showAlreadySentDialog, setShowAlreadySentDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const navigate = useNavigate();
  const { addExperience } = useUserProgress();

  useEffect(() => {
    const checkIfUserHasSentMessageToday = async () => {
      try {
        // Get today's date at 00:00:00
        const startOfToday = new Date();
        startOfToday.setHours(0, 0, 0, 0);
        const timestampStartOfToday = Timestamp.fromDate(startOfToday);

        // Query messages where authorId == uid and createdAt >= start of today
        const messagesRef = collection(db, `messages-${getEnvKey('ENV')}`);
        const q = query(
          messagesRef,
          where('createdAt', '>=', timestampStartOfToday)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // User has already sent a message today
          setShowAlreadySentDialog(true);
        }
      } catch (error) {
        console.error('Error checking if user has sent message today: ', error);
      }
    };

    // checkIfUserHasSentMessageToday();
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (message.trim() === '') {
      alert('Please enter a message.');
      return;
    }

    try {
      const user = auth.currentUser;

      await addDoc(collection(db, `messages-${getEnvKey('ENV')}`), {
        content: message,
        createdAt: Timestamp.now(),
      });
      setMessage('');
      console.log('Message sent:', message);

      // Call addExperience() to award XP
      addExperience(1000);

      // Show success dialog
      setShowSuccessDialog(true);
    } catch (error) {
      console.error('Error adding message: ', error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen">
      <div className="absolute top-0 left-0">
        <ScrollingHeader>
          <BackButton />
        </ScrollingHeader>
      </div>
      <h1 className="font-glass text-white text-4xl">Bärchen Post</h1>

      {showAlreadySentDialog ? (
        <Modal
          title="Das geht nur einmal am Tag"
          message="Warte bis morgen, um eine weitere zu schicken Mäuschen."
          button="Grmpf!"
          onClose={() => navigate('/home')}
        />
      ) : showSuccessDialog ? (
        <Modal
          title="Nachricht gesendet"
          message="Deine Nachricht wurde erfolgreich gesendet!"
          xp="1000"
          button="Hihi"
          onClose={() => navigate('/home')}
        />
      ) : (
        <form onSubmit={handleSubmit} className="m-5 px-4 w-full max-w-xl">
          <textarea
            className="w-full h-80 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Schreibe deine Nachricht hier..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            type="submit"
            className="mt-3 px-4 py-2 bg-jam-glass text-white font-bold rounded-md"
          >
            Nachricht senden
          </button>
        </form>
      )}
    </div>
  );
};

export default BearMailForm;
