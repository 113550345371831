import React from 'react';
import useAuth from '../../../utils/useAuth';
import './rewards.css';
import imageIcon from '../../../assets/rewards/image-icon.png';
import videoIcon from '../../../assets/rewards/video-icon.png';
import textIcon from '../../../assets/rewards/text-icon.png';
import questionMark from '../../../assets/rewards/question-mark-icon.png';
import present from '../../../assets/rewards/gift-icon.png';
import audio from '../../../assets/rewards/audio-icon.png';
import flameIcon from '../../../assets/rewards/flame-icon.png';
import levelEnd from '../../../assets/rewards/level-end.png';
import check from '../../../assets/check.svg';
import { useUserProgress } from '../../../state/use-user-progress';
import BackButton from '../../utils/back-button/back-button';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import { useNavigate } from 'react-router-dom';
import { RewardStage, RewardLevel } from '../rewards/reward-models';

const BearRewards: React.FC = () => {
  useAuth();
  const { bearLevels } = useUserProgress();
  const navigate = useNavigate();

  function handleRewardClick(reward: RewardStage): void {
    if(!reward.burned) {
      navigate(`/bear-rewards/level${reward.level}-stage${reward.stage}`, { state: reward });
    }
  }

  function mapRewardTypeToIcon(type: RewardStage['type']): string {
    switch (type) {
      case 'image':
        return imageIcon;
      case 'video':
        return videoIcon;
      case 'video-file':
            return videoIcon;
      case 'text':
        return textIcon;
      case 'link':
        return textIcon;
      case 'audio':
        return audio;
      case 'secret':
        return questionMark;
      case 'hot':
        return flameIcon;
      case 'hot-video':
        return flameIcon;
      case 'levelEnd':
        return levelEnd;
      case 'present':
        return present;
      default:
        return imageIcon;
    }
  }

  function mapRewardLevelsToElements(rewards: Array<RewardLevel>): JSX.Element[] {
    return rewards.map((rewardLevel, index) => (
      <div key={index} className="flex flex-col items-center w-full mb-8 last:mb-0">
        <h2 className="flex justify-center items-center w-11/12 h-12 mb-8 mr-5 ml-5 text-2xl font-bold text-white bg-jam-glass rounded-3xl">
          Level {rewardLevel.level} - {rewardLevel.levelName}
        </h2>
        <div className="flex flex-row justify-center items-center">
          {mapRewardStagesToElements(rewardLevel.stages)}
        </div>
      </div>
    ));
  }

  function mapRewardStagesToElements(stages: Array<RewardStage>): JSX.Element {
    return (
      <div className="flex flex-col w-[20.125rem] z-10">
        {stages.map((reward, index) => {
          const icon = mapRewardTypeToIcon(reward.type);
          return (
            <div
              key={index}
              onClick={() => handleRewardClick(reward)}
              className="item relative flex justify-center w-[5.375rem] h-[5.375rem] last:mb-[2.5rem] cursor-pointer"
            >
              <div className="z-10 relative flex items-center justify-center bg-home-tile w-[5.375rem] h-[5.375rem] rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md">
                <img src={icon} alt="reward" className="w-[4rem]" />
              </div>
              <div
                className={`z-0 absolute flex justify-center items-end bottom-0 h-16 w-2/3 mb-[-1.25rem] text-white font-bold rounded-b-[1.25rem] ${
                  reward.burned ? 'bg-yellow-700' : 'bg-green-700'
                }`}
              >
                <div className="flex gap-1">
                  {reward.stage} {reward.burned && <img src={check} alt="checkmark" />}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="bg-gray-800">
      <ScrollingHeader className="z-30">
        <BackButton className="bg-black" />
      </ScrollingHeader>
      <div className="pt-20">{mapRewardLevelsToElements(bearLevels)}</div>
    </div>
  );
};

export default BearRewards;
