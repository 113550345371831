import React from 'react';
import './present.css';
import tickets from '../../../../assets/rewards/tickets.png';

const PresentBday2024: React.FC = () => {
    return (
        <div className="present">
            <h1>Herzlichen Glückwunsch Jana</h1>
            <hr />
            <p>Ich weiß du hättest damit niemals gerechnet, aber ich habe dir Karten gekauft für den Dino Park bei Nacht in Münchhagen</p>
            <div className="flex justify-center">
                <img src={tickets} alt="" className="h-28" />
            </div>
            <hr />
            <p>Ich freue mich schon auf einen wunderschönen Abend mit dir ✨</p>
            <p><b>Ich liebe dich über alles mein Herz, mein Honig .. meine Frau ❤️</b></p>
        </div>
    );
};

export default PresentBday2024;