import React, { useEffect, useState } from 'react';
import { db } from '../../../firebaseconfig';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { getEnvKey } from '../../../utils/getEnvKey';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import BackButton from '../../utils/back-button/back-button';

interface Message {
  id: string;
  content: string;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
}

const BearMailList: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const q = query(collection(db, `messages-${getEnvKey('ENV')}`), orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const msgs: Message[] = [];
      querySnapshot.forEach((doc) => {
        msgs.push({ id: doc.id, ...(doc.data() as Omit<Message, 'id'>) });
      });
      setMessages(msgs);
      console.log('messages retrieved: ', msgs);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <ScrollingHeader>
        <BackButton />
      </ScrollingHeader>
      <div className="w-full max-w-xl mx-auto mt-16">
        {messages.map((msg) => (
          <div key={msg.id} className="mb-4 p-3 border border-gray-300 rounded-md">
            <p className="whitespace-pre-wrap text-white">{msg.content}</p>
            <p className="text-xs text-white mt-2">
              {new Date(msg.createdAt.seconds * 1000).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default BearMailList;
