// ActivitySwitch.tsx
import React, { useEffect, useState } from 'react';
import {
  collection,
  doc,
  onSnapshot,
  addDoc,
  setDoc,
  query,
  orderBy,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../../firebaseconfig';

type Animal = 'Gemeinbär' | 'Doofmaus';

interface ActivityLog {
  id: string;
  state: Animal;
  date: string;
}

interface ActivitySwitchProps {
  className?: string;
}

const ActivitySwitch: React.FC<ActivitySwitchProps> = ({ className }) => {
  const [activeAnimal, setActiveAnimal] = useState<Animal>('Gemeinbär');
  const [logs, setLogs] = useState<ActivityLog[]>([]);

  // State variables for sentences
  const [gemeinbärSentences, setGemeinbärSentences] = useState<string[]>([]);
  const [doofmausSentences, setDoofmausSentences] = useState<string[]>([]);
  const [bothSentences, setBothSentences] = useState<string[]>([]);

  // State variables for the speech bubble
  const [currentSentence, setCurrentSentence] = useState<string | null>(null);
  const [speakingAnimal, setSpeakingAnimal] = useState<Animal | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const currentDocRef = doc(db, 'activity-switch', 'current');
      const logsCollectionRef = collection(currentDocRef, 'logs');

      // Fetch the current state
      getDoc(currentDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setActiveAnimal(data.state as Animal);
        }
      });

      // Listen for log changes
      const logsQuery = query(logsCollectionRef, orderBy('date', 'desc'));
      const unsubscribeLogs = onSnapshot(logsQuery, (snapshot) => {
        const logData: ActivityLog[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          state: doc.data().state as Animal,
          date: doc.data().date,
        }));
        setLogs(logData);
      });

      // Fetch sentences
      try {
        // Paths to the sentence subcollections
        const sentencesDocRef = doc(db, 'activity-switch', 'sentences');
        const gemeinbärSentencesRef = collection(sentencesDocRef, 'Gemeinbär');
        const doofmausSentencesRef = collection(sentencesDocRef, 'Doofmaus');
        const bothSentencesRef = collection(sentencesDocRef, 'both');

        // Fetch sentences from Firestore
        const [gemeinbärSnap, doofmausSnap, bothSnap] = await Promise.all([
          getDocs(gemeinbärSentencesRef),
          getDocs(doofmausSentencesRef),
          getDocs(bothSentencesRef),
        ]);

        // Map the documents to sentence arrays
        setGemeinbärSentences(
          gemeinbärSnap.docs.map((doc) => doc.data().sentence)
        );
        setDoofmausSentences(
          doofmausSnap.docs.map((doc) => doc.data().sentence)
        );
        setBothSentences(bothSnap.docs.map((doc) => doc.data().sentence));
      } catch (error) {
        console.error('Error fetching sentences:', error);
      }

      return () => {
        unsubscribeLogs();
      };
    };

    fetchData();
  }, []);

  const handleSwitch = async (newState: Animal) => {
    if (newState === activeAnimal) {
      // Do nothing if the active button is clicked
      return;
    }

    const previousAnimal = activeAnimal; // Store the current active animal

    const date = new Date().toISOString();

    const currentDocRef = doc(db, 'activity-switch', 'current');
    const logsCollectionRef = collection(currentDocRef, 'logs');

    // Update the current state
    await setDoc(currentDocRef, {
      state: newState,
      date,
    });

    // Add a new log entry
    await addDoc(logsCollectionRef, {
      state: newState,
      date,
    });

    // Update local state
    setActiveAnimal(newState);
    console.log('Switched to', newState);

    // Show speech bubble with a random sentence for the previous animal
    let animalSentences =
      previousAnimal === 'Gemeinbär' ? gemeinbärSentences : doofmausSentences;

    // Combine animal-specific sentences with 'both' sentences
    const combinedSentences = animalSentences.concat(bothSentences);

    if (combinedSentences.length > 0) {
      const randomSentence =
        combinedSentences[
          Math.floor(Math.random() * combinedSentences.length)
        ];
      setCurrentSentence(randomSentence);
      setSpeakingAnimal(previousAnimal); // Set the speaking animal to the previous one
    } else {
      // Handle the case when there are no sentences
      setCurrentSentence('Keine Sätze verfügbar.');
      setSpeakingAnimal(previousAnimal);
    }

    // Clear the speech bubble after 5 seconds
    setTimeout(() => {
      setCurrentSentence(null);
      setSpeakingAnimal(null);
    }, 5000);
  };

  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className="flex space-x-4">
        {/* Gemeinbär Button */}
        <button
          className={`text-6xl p-6 rounded-xl ${
            activeAnimal === 'Gemeinbär' ? 'bg-jam-glass' : 'bg-gray-900'
          }`}
          onClick={() => handleSwitch('Gemeinbär')}
        >
          <div className="relative flex items-center justify-center">
          { activeAnimal === 'Doofmaus' && <span className="absolute -top-4 text-slate-800 text-sm">Drück mich</span> }
            <span
              className={`${
                activeAnimal === 'Gemeinbär' ? '' : 'opacity-20'
              }`}
            >
              🐻
            </span>
            {/* Speech Bubble for Gemeinbär */}
            {speakingAnimal === 'Gemeinbär' && currentSentence && (
              <div className="absolute min-w-40 text-sm -top-12 left-1/2 transform -translate-x-1/2 bg-white text-black p-2 rounded-lg shadow-md">
                {currentSentence}
              </div>
            )}
          </div>
        </button>

        {/* Doofmaus Button */}
        <button
            className={`text-6xl p-6 rounded-xl ${
                activeAnimal === 'Doofmaus' ? 'bg-jam-glass' : 'bg-gray-900'
            }`}
            onClick={() => handleSwitch('Doofmaus')}
            >
            <div className="relative flex items-center justify-center">
                { activeAnimal === 'Gemeinbär' && <span className="absolute -top-4 text-slate-800 text-sm">Drück mich</span> }
                <span
                className={`${
                    activeAnimal === 'Doofmaus' ? '' : 'opacity-20'
                }`}
                >
                🐭
                </span>
                {/* Speech Bubble for Doofmaus */}
                {speakingAnimal === 'Doofmaus' && currentSentence && (
                <div className="absolute min-w-40 text-sm -top-12 left-1/2 transform -translate-x-1/2 bg-white text-black p-2 rounded-lg shadow-md">
                    {currentSentence}
                </div>
                )}
            </div>
        </button>
      </div>

      {/* Display the latest log */}
      <div className="mt-4 mb-4 py-2 px-4 bg-slate-900 text-white rounded-lg">
        {logs.length > 0 && (
          <div key={logs[0].id} className="text-sm text-center">
            <p>Gewechselt zu {logs[0].state} am</p>
            <p>{new Date(logs[0].date).toLocaleString()}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivitySwitch;
