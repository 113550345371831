import { useContext } from "react";
import { UserProgressContext, UserProgressContextType } from "./user-progress-provider";

export const useUserProgress = (): UserProgressContextType => {
    const context = useContext(UserProgressContext);
    if (context === undefined) {
      throw new Error('useUserProgress must be used within a UserProgressProvider');
    }
    return context;
  };
  