import React, { useState } from 'react';
import './present.css';

const LevelThreeEnd: React.FC = () => {
    const [step, setStep] = useState<number>(0);

    const nextStep = () => {
        setStep(step + 1);
    };

    const images = {
        image1: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_4454.jpg?alt=media&token=5bb6f0ac-7ae4-4863-9baa-3f6ab995e66c',
        image2: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_4750.jpg?alt=media&token=3667fd23-a08d-47eb-857a-0ff2159234aa',
        image3: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5037.jpg?alt=media&token=980828c4-ab51-4a08-aa07-0e07e180d728',
        image4: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5039.jpg?alt=media&token=68faf177-8285-402f-8d33-4771f022821a',
        image5: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5040.jpg?alt=media&token=8e970f70-156d-4cc4-88d9-ac710cd54dd9',
        image6: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5043.jpg?alt=media&token=ace6fcc4-4093-4bce-a0f1-cc8591f4b6d0',
        image7: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5044.jpg?alt=media&token=ced6c3f5-5b23-46db-b826-b56950e6d3ab',
        image8: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5047.jpg?alt=media&token=56e695f8-5abc-43bc-a363-7366396b1565',
        image9: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5048.jpg?alt=media&token=968450e7-9191-4b28-854a-e038d1cff51c',
        image10: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5049.jpg?alt=media&token=0eea36de-c483-4232-ba52-b3d863e1f5dd',
        image11: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5050.jpg?alt=media&token=b7c1d747-4c64-4a4d-8a3c-08d06b05d187',
        image12: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5051.jpg?alt=media&token=254bfa6a-165d-40d0-bdfa-e608c7b380aa',
        image13: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5053.jpg?alt=media&token=bb2e09f0-e2e8-4399-848d-00479123c8c3',
    }

    return (
        <div className="flex flex-col items-center present text-center pb-10 m-[-1rem] p-4 bg-slate-900">
            <h1>Sexy Bärchen Content</h1>
            <p>Du hast Level 3 beendet.</p>
            <p>Ich denke du weißt das ich mir Trauer mit Sexualität leichter mache. Andere Frauen oder Pornos sind für mich nicht intressant. Ich weiß nicht wie es bei dir ist. Ich dachte vielleicht willst du dir auch mal was gutes tun und hättest Lust auf ein sexy Bärchen. Die Bilder waren nur für deine Augen bestimmt. Schau es dir an oder speicher dir den Drive Link für später oder schließ einfach die Belohnung.</p>
            <p>Alle Bilder und ein sexy Video extra gibts in guter Qualität im <a href="https://drive.google.com/drive/folders/1-9FPEFXCt1wg6_6Y1Ub5f6DPbuX5MRVY?usp=drive_link" className="text-blue-400">Google Drive</a></p>
            <div className="flex flex-col items-center">
                {step >= 1 && (
                    <img src={images.image1} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 2 && (
                    <img src={images.image2} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 3 && (
                    <img src={images.image3} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 4 && (
                    <img src={images.image4} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 5 && (
                    <img src={images.image5} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 6 && (
                    <img src={images.image6} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 7 && (
                    <img src={images.image7} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 8 && (
                    <img src={images.image8} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 9 && (
                    <img src={images.image9} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 10 && (
                    <img src={images.image10} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 11 && (
                    <img src={images.image11} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 12 && (
                    <img src={images.image12} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 13 && (
                    <img src={images.image13} className="my-4 w-[20rem] pointer-events-none" />
                )}
            </div>
            {step <= 12 && (
                <button className="w-48 p-2 mx-8 mt-4 font-home font-bold text-1xl bg-jam-glass border-1 border-white rounded-xl shadow-2xl" onClick={nextStep}>Weiter {`(${step + 1}/13)`}</button>
            )}
        </div>
    );
};

export default LevelThreeEnd;