
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  Timestamp,
  doc,
} from 'firebase/firestore';
import { getEnvKey } from '../utils/getEnvKey';
import { db } from '../firebaseconfig';


export const deleteTodaysMessage = async () => {
  try {
    // Get today's date at 00:00:00
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    const timestampStartOfToday = Timestamp.fromDate(startOfToday);

    // Query messages where authorId == uid and createdAt >= start of today
    const messagesRef = collection(db, `messages-${getEnvKey('ENV')}`);
    const q = query(
      messagesRef,
      where('createdAt', '>=', timestampStartOfToday)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Loop through and delete each message
      for (const docSnapshot of querySnapshot.docs) {
        await deleteDoc(
          doc(db, `messages-${getEnvKey('ENV')}`, docSnapshot.id)
        );
        console.log(`Deleted message with ID: ${docSnapshot.id}`);
      }
      console.log('All messages from today have been deleted.');
    } else {
      console.log('No messages from today found for this user.');
    }
  } catch (error) {
    console.error("Error deleting today's message: ", error);
  }
};
