// src/components/Card.tsx
import React from 'react';
import "./memory-game.css";
import heart from '../../../assets/home/win-heart.png';

export interface CardType {
    id: number;
    src: string;
    matched: boolean;
  }

interface CardProps {
  card: CardType;
  handleChoice: (card: CardType) => void;
  flipped: boolean;
  disabled: boolean;
}

interface CardProps {
    card: CardType;
    handleChoice: (card: CardType) => void;
    flipped: boolean;
    disabled: boolean;
  }
  
const Card: React.FC<CardProps> = ({ card, handleChoice, flipped, disabled }) => {
    const handleClick = () => {
      if (!disabled && !flipped) {
        handleChoice(card);
      }
    };
  
    return (
      <div className="relative w-24 h-32" onClick={handleClick}>
        <div
          className={`relative w-full h-full preserve-3d transition-transform duration-500 ${
            flipped ? 'rotate-y-180' : ''
          }`}
        >
          {/* Back Face */}
          <div className="absolute top-0 left-0 w-full h-full bg-amber-900 border-2 border-amber-950  rounded-lg flex items-center justify-center backface-hidden">
            <img src={heart} alt="heart" className="h-8"/>
          </div>
          {/* Front Face */}
          <img
            className="absolute top-0 left-0 w-full h-full object-cover rounded-lg backface-hidden transform rotate-y-180"
            src={card.src}
            alt="card front"
          />
        </div>
      </div>
    );
};

export default Card;
