import React, { useState, useEffect } from 'react';
import Card, { CardType } from './memory-card';
import "./memory-game.css";
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import BackButton from '../../utils/back-button/back-button';
import { useUserProgress } from '../../../state/use-user-progress';
import { useNavigate } from 'react-router-dom';

const cardImages = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2F0f5a460b-35ac-4315-9308-d569380eab61-min.jpg?alt=media&token=3e8585f6-ba34-4b28-8e00-dd63b6ff84b5' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2F7e3a13b8-acb5-4046-820b-d50313ce6306-min.jpg?alt=media&token=6de3a34e-c557-4a42-acb3-7ae080cb7b50' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2F8A2AF662-18DE-460E-A736-33FB5D750083-min.JPG?alt=media&token=b17702d8-ab1c-4b12-b654-314f2f3baa72' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_2610-min.jpg?alt=media&token=2b98ab9e-b11c-464b-97c7-9340b395b16b' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3185-min.JPG?alt=media&token=3535f166-9ab8-49e2-9788-20ffd71bfcc2' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3863-min.jpg?alt=media&token=0bd6a87e-b466-4d0f-a4a9-eecab0236fa1' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3931-min.jpg?alt=media&token=2a631698-1b0c-407f-b21a-cb4613e47108' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3997-min.jpg?alt=media&token=90b8dc80-9307-451a-a7ef-260663094bb8' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3361_schloss.png?alt=media&token=5798c027-c710-4f4c-91bb-61aa9ffe9e33' },
];

const MemoryGame: React.FC = () => {
  const [cards, setCards] = useState<CardType[]>([]);
  const [firstChoice, setFirstChoice] = useState<CardType | null>(null);
  const [secondChoice, setSecondChoice] = useState<CardType | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [matches, setMatches] = useState<number>(0);
  const [turns, setTurns] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [awardedXP, setAwardedXP] = useState<number>(0);
  const { addExperience } = useUserProgress();
  const navigate = useNavigate();

  // Function to calculate XP based on turns
  const calculateXP = (turns: number): number => {
    if (turns <= 20) {
      return 750;
    } else if (turns <= 25) {
      return 500;
    } else {
      return 350;
    }
  };

  // Shuffle cards and start a new game
  const shuffleCards = () => {
    const shuffledCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card, index) => ({
        ...card,
        id: index,
        matched: false,
      }));

    setCards(shuffledCards);
    setFirstChoice(null);
    setSecondChoice(null);
    setMatches(0);
    setTurns(0);
    setDisabled(false);
    setIsModalOpen(false);
  };

  // Handle a card choice
  const handleChoice = (card: CardType) => {
    if (firstChoice && card.id === firstChoice.id) {
      // Same card clicked twice; ignore the click
      return;
    }

    firstChoice ? setSecondChoice(card) : setFirstChoice(card);
  };

  // Compare two selected cards
  useEffect(() => {
    if (firstChoice && secondChoice) {
      setDisabled(true);
      if (firstChoice.src === secondChoice.src) {
        // Cards match
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.src === firstChoice.src ? { ...card, matched: true } : card
          )
        );
        setMatches((prevMatches) => prevMatches + 1);
        resetTurn();
      } else {
        // Cards do not match
        setTimeout(() => resetTurn(), 1000);
      }
    }
  }, [firstChoice, secondChoice]);

  // Reset choices & increase turn
  const resetTurn = () => {
    setFirstChoice(null);
    setSecondChoice(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  // Check if the game is won and show modal
  useEffect(() => {
    if (matches === cardImages.length) {
      const xp = calculateXP(turns);
      setAwardedXP(xp);
      setIsModalOpen(true);
      addExperience(xp);
    }
  }, [matches, turns]);

  // Start a new game automatically
  useEffect(() => {
    shuffleCards();
  }, []);

  return (
    <>
      <ScrollingHeader>
        <BackButton className="bg-black" />
      </ScrollingHeader>
      <div className="min-w-[360px] bg-jam-glass min-h-screen flex flex-col items-center py-4 pt-20">
        <h1 className="text-2xl font-bold mb-4 text-white">Maus & Bär Memory</h1>
        <p className="text-lg mb-2 text-white">
          Versuche: {turns} | Gefunden: {matches}/{cardImages.length}
        </p>
        <div className="grid grid-cols-3 gap-2 px-4">
          {cards.map((card) => (
            <Card
              key={card.id}
              card={card}
              handleChoice={handleChoice}
              flipped={
                card.id === firstChoice?.id ||
                card.id === secondChoice?.id ||
                card.matched
              }
              disabled={disabled}
            />
          ))}
        </div>
      </div>

      {/* Modal */}
      { isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-slate-900 text-white p-4 rounded-2xl border-4 border-amber-900 mx-4 text-center">
            <h2 className="text-2xl font-bold mb-4">Du hast gewonnen!</h2>
            <p className="text-lg mb-2">
              Du hast alle Paare in {turns} Versuchen gefunden.
            </p>
            <p className="text-3xl font-bold font-gaming mb-3">
                +{awardedXP}XP
            </p>
            <div className="flex justify-center gap-3">
                <button
                    onClick={() => {
                        setIsModalOpen(false);
                        shuffleCards();
                    }}
                    className="p-3  font-home font-bold text-1xl bg-jam-glass rounded-xl shadow-2xl"
                >
                    Nochmal spielen
                </button>
                <button className="p-3 font-home font-bold text-1xl bg-jam-glass rounded-xl shadow-2xl" onClick={() => navigate(-1)}>Zurück</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoryGame;
