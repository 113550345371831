import React from 'react';

interface VideoPlayerProps {
    id: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ id }) => {

    if (!id) {
        return <div>Invalid YouTube URL</div>;
    }

    return (
        <iframe
            width="100%"
            height="550"
            src={`https://www.youtube.com/embed/${id}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
        ></iframe>
    );
};

export default VideoPlayer;