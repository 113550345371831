import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


interface BackButtonProps {
    className?: string;
    callback?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ className = '', callback }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const callbackRef = useRef(callback);

  const handleBack = () => {
    navigate(-1);
    if(callback) {
      callback();
    }
  };

  useEffect(() => {
    // This effect will run when the location changes (e.g., from back button navigation)
    const currentCallback = callbackRef.current;
    return () => {
      if (currentCallback) {
        currentCallback();
      }
    };
  }, [location]);

  return (
    <div className={`w-full p-2 ${className}`}>
      <div className="flex items-center">
        <button
          onClick={handleBack}
          className="text-white px-4 py-2 rounded-md border border-1 border-white"
        >
          ← Zurück
        </button>
      </div>
    </div>
  );
};

export default BackButton;