// Modal.tsx
import React from 'react';

interface ModalProps {
  title: string;
  message: string;
  button?: string;
  xp?: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, message, button, xp, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-white bg-black bg-opacity-70">
      <div className="flex flex-col items-center justify-center w-[21.625rem] bg-jam-glass p-4 rounded-2xl border-4 border-gray-900">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p className="mb-4 text-center">{message}</p>
        {
          xp && (
            <p className="text-3xl font-bold font-gaming mb-3">
              +{xp}XP
            </p>
          )
        }
        <button
          onClick={onClose}
          className="w-2/3 p-2 mx-8 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl"
        >
          { button ? `${button}` : 'OK'}
        </button>
      </div>
    </div>
  );
};

export default Modal;
