import React from 'react';
import spinnerSvg from '../../../assets/spinner.svg';

interface LoadingProps {
    loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
    if (loading) {
        return (
            <div className="absolute top-0 left-0 h-screen w-full bg-black bg-opacity-20">
                <div className={`flex justify-center items-center h-screen w-full`}>
                    <div className="flex flex-row text-4xl items-center">
                        <img src={spinnerSvg} alt="loading spinner" className="w-12 animate-spin mr-2" />
                    </div>
                </div>
            </div>
        );
    }

    return null; // or any other fallback UI
};

export default Loading;