import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bear from '../../../assets/teddy-bear.png';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebaseconfig';
import Loading from '../../utils/loading/loading';

const Login: React.FC = () => {
    const [gemeinbaer, setGemeinbaer] = useState('');
    const [liebmaus, setLiebmaus] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const email = `${liebmaus.toLowerCase()}-${gemeinbaer.toLowerCase()}@gemeinbaer.de`;
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setLoading(false);
            navigate('/home');
        } catch (error) {
            setErrorMessage('Authentication failed. Please try again.');
            setLoading(false);
        }
    };

    return (
        <section className="bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen">
                <Loading loading={loading}/>
                <div className="flex items-center mb-6 text-2xl font-semibold text-white">
                    <img className="w-8 h-8 mr-2" src={bear} alt="logo"/>
                    Gemeinbär Park
                </div>
                <div className="rounded-lg shadow border bg-gray-800 border-gray-700 bg-jam-glass">
                    <div className="p-6 space-y-4">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-white">
                            You shall not pass!
                        </h1>
                        <p className="margin-0 padding-0 text-white">
                            Es sei denn, du bist meine Liebmaus.
                        </p>
                        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                        <form className="space-y-4" onSubmit={handleLogin}>
                            <div>
                                <label htmlFor="gemeinbaer" className="block mb-2 text-sm font-medium text-white">
                                    Dein Gemeinbär
                                </label>
                                <input
                                    type="text"
                                    name="gemeinbaer"
                                    id="gemeinbaer"
                                    className="border rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Name des Bären"
                                    value={gemeinbaer}
                                    onChange={(e) => setGemeinbaer(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="liebmaus" className="block mb-2 text-sm font-medium text-white">
                                    Liebmaus
                                </label>
                                <input
                                    type="text"
                                    name="liebmaus"
                                    id="liebmaus"
                                    className="border rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Name der Maus"
                                    value={liebmaus}
                                    onChange={(e) => setLiebmaus(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="••••••••"
                                    className="border rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-gray-700 hover:bg-primary-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-gray-600 focus:ring-primary-800"
                            >
                                Park betreten
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;