import React, { useState, useEffect, useRef } from 'react';
import { useUserProgress } from '../../state/use-user-progress';
import BackButton from '../utils/back-button/back-button';

const KissGame = () => {
  // State variables
  const [gameStarted, setGameStarted] = useState(false);
  const [kissCounter, setKissCounter] = useState(0);
  const [resetThreshold, setResetThreshold] = useState(
    Math.floor(Math.random() * 7) + 14
  );
  const [timerSeconds, setTimerSeconds] = useState(20);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [loverName, setLoverName] = useState('');
  const [wonExperience, setWonExperience] = useState(0);
  const { addExperience } = useUserProgress();

  const buttonRef = useRef(null);

  // Constants and labels
  const TIMER_DEFAULT = 20;
  const HEADLINE_LABEL = 'Knutschen?';
  const TIMER_LABEL = 'Knutschie Timer: ';
  const COUNTER_LABEL = 'Kiss Me Counter: ';
  const RULES_LABELS = [
    '* 3 Bonus Knutschies, wenn du mehr als 15 sammelst.',
    '* Vorsicht zwischen 14 und 20 kann dein Knutschi Counter zurückgesetzt werden.',
  ];
  const MOVING_BUTTON_LABEL = 'Ja 😘';
  const LOVER_NAMES = [
    'Undercoverfrüchtchen ❤️',
    'Liebmäuschen 🐭❤️',
    'Doofmäuschen 🐭❤️',
    'Honig 🍯❤️',
    'Kirschchen 🍒❤️',
  ];
  const RESTART_BUTTON = 'Nochmal 😏?';
  const HEADLINE_END = ' Knutschies für mein ';
  const BUTTON_PLACEMENT_PADDING = 75;

  // Calculate the center position when the component mounts
  useEffect(() => {
    centerButton();
  }, []);

  // Timer effect
  useEffect(() => {
    let timer: NodeJS.Timer;
    if (gameStarted && timerSeconds > 0) {
      timer = setInterval(() => {
        setTimerSeconds((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timerSeconds <= 0) {
      endGame();
    }
    return () => clearInterval(timer);
  }, [gameStarted, timerSeconds]);

  const centerButton = () => {
    if (buttonRef.current) {
      const button: HTMLButtonElement = buttonRef.current;
      const buttonWidth = button.offsetWidth;
      const buttonHeight = button.offsetHeight;
      const centerTop = (window.innerHeight - buttonHeight) / 2;
      const centerLeft = (window.innerWidth - buttonWidth) / 2;
      setButtonPosition({ top: centerTop, left: centerLeft });
    }
  }

  // Move the button to a random position
  const moveButton = () => {
    const minTop = 170; // Minimum top position
    const maxTop = window.innerHeight - BUTTON_PLACEMENT_PADDING;

    // Ensure maxTop is not less than minTop
    const adjustedMaxTop = Math.max(minTop + 1, maxTop);

    const newTop = minTop + Math.random() * (adjustedMaxTop - minTop);
    const newLeft =
      Math.random() * (window.innerWidth - BUTTON_PLACEMENT_PADDING);

    setButtonPosition({ top: newTop, left: newLeft });
    setKissCounter((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount > resetThreshold) {
        setResetThreshold(Math.floor(Math.random() * 7) + 14);
        return 0;
      }
      return newCount;
    });
  };


  // Start the game
  const startGame = () => {
    setGameStarted(true);
    moveButton();
  };

  // End the game
  const endGame = () => {
    let finalKissCount = kissCounter;
    if (kissCounter >= 15) {
      finalKissCount += 3;
    }
    setKissCounter(finalKissCount);
    setLoverName(
      LOVER_NAMES[Math.floor(Math.random() * LOVER_NAMES.length)]
    );
    setShowEndScreen(true);
    setGameStarted(false);
    centerButton();
    if(kissCounter >= 20) {
      addExperience(1500);
      setWonExperience(1500);
    } else if(kissCounter >= 15) {
      addExperience(1000);
      setWonExperience(1000);
    } else {
      addExperience(500);
      setWonExperience(500);
    }
  };

  // Restart the game
  const restartGame = () => {
    setGameStarted(false);
    setKissCounter(0);
    setTimerSeconds(TIMER_DEFAULT);
    setResetThreshold(Math.floor(Math.random() * 7) + 14);
    setShowEndScreen(false);

    // Reset the button position to center
    if (buttonRef.current) {
      const button: HTMLButtonElement = buttonRef.current;
      const buttonWidth = button.offsetWidth;
      const buttonHeight = button.offsetHeight;
      const centerTop = (window.innerHeight - buttonHeight) / 2;
      const centerLeft = (window.innerWidth - buttonWidth) / 2;

      setButtonPosition({ top: centerTop, left: centerLeft });
    }
  };

  // Handle mouse over event on the button
  const handleMouseOver = () => {
    if (gameStarted) {
      moveButton();
    } else {
      startGame();
    }
  };

  return (
    <div className='h-screen w-screen bg-jam-glass'>
      <BackButton />
      <h1 className='w-full text-center pt-4 text-4xl font-glass text-white'>
        {!showEndScreen && HEADLINE_LABEL}
      </h1>

      {!showEndScreen && (
        <>
          <div className='flex flex-col items-center w-full text-white'>
            <p>
              {TIMER_LABEL} {timerSeconds}
            </p>
            <p>
              {COUNTER_LABEL} {kissCounter}
            </p>
            <div className={`p-4 ${gameStarted ? `hidden` : ''}`}>
              <p>Regeln:</p>
              <div className='*:pl-2'>
                {
                  RULES_LABELS.map((rule, index) => (
                    <p key={index}>{rule}</p>
                  ))
                }
              </div>
            </div>
          </div>
          <button
            ref={buttonRef}
            onClick={handleMouseOver}
            style={{
              position: 'absolute',
              top: buttonPosition.top,
              left: buttonPosition.left,
            }}
            className='bg-white text-1xl p-2 rounded-lg shadow-md font-bold'
          >
            {MOVING_BUTTON_LABEL}
          </button>
        </>
      )}

      {showEndScreen && (
        <div className='flex flex-col items-center w-full h-screen mt-36 text-center font-bold text-2xl font-home'>
          <h1 className='w-full text-center text-4xl font-glass text-white'>
            <p>{`${kissCounter} ${HEADLINE_END}`}</p>
            <p>{`${loverName}`}</p>
            <p className='font-gaming mt-3'>{`+${wonExperience}XP`}</p>
          </h1>
          <button className='mt-5 bg-white text-1xl p-2 rounded-lg shadow-md font-bold' onClick={restartGame}>{RESTART_BUTTON}</button>
        </div>
      )}
    </div>
  );
};

export default KissGame;
