import React, { useState, useRef, useEffect } from 'react';
import glassSvg from '../../../assets/jam/glass.svg';
import hat from '../../../assets/jam/hat.svg';
import pumpkin from '../../../assets/jam/pumpkin.svg';
import tree from '../../../assets/jam/tree.svg';
import cat from '../../../assets/jam/cat.svg';
import potion from '../../../assets/jam/potion.svg';
import bat from '../../../assets/jam/bat.svg';
import '../../../animation.css';
import { doc, getDocs, query, updateDoc, collection, orderBy, getDoc } from 'firebase/firestore';
import { db } from '../../../firebaseconfig';
import { getCachedData, invalidateCache } from '../../../utils/cacheReader';
import { getEnvKey } from '../../../utils/getEnvKey';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import BackButton from '../../utils/back-button/back-button';
import Loading from '../../utils/loading/loading';
import { useUserProgress } from '../../../state/use-user-progress';



interface Note {
    id: number;
    text: string;
    pulled: boolean;
    jarVersion: number;
}

function getAvailableNotes(notes: Note[], jarVersion: number): Array<Note> {
    return notes.filter(note => !note.pulled && note.jarVersion === jarVersion);
}

function getFillHeight(notes: Array<Note>, jarVersion: number, maximumFillHeight: number): number {
    const availableNotesCount: number = getAvailableNotes(notes, jarVersion).length;
    const notesCountOfThisVersion = notes.filter(note => note.jarVersion === jarVersion).length;
    const ratio = availableNotesCount / notesCountOfThisVersion;
    if(availableNotesCount === 0) return 0;
    return Math.floor(maximumFillHeight * ratio);
}

const JamGlass: React.FC = () => {
    const maximumFillHeight: number = 310;
    const JAR_EMPTY_NOTE = "Das Glas ist leer :(";

    const [fillHeight, setFillHeight] = useState(maximumFillHeight);
    const [notes, setNotes] = useState<Array<Note>>([]);
    const [currentNote, setCurrentNote] = useState<string>(JAR_EMPTY_NOTE);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [jarVersion, setJarVersion] = useState<number>(0);
    const [selectedJarVersion, setSelectedJarVersion] = useState<number>(0);
    const dialogRef = useRef<HTMLDialogElement>(null);
    const glassRef = useRef<HTMLDivElement>(null);
    const { addExperience } = useUserProgress();

    function pullNote() {
        const availableNotes: Array<Note> = getAvailableNotes(notes, selectedJarVersion);
        if (availableNotes.length) {
            const nextNote = availableNotes[0];
            nextNote.pulled = true;
            setCurrentNote(nextNote.text);
            postNote(nextNote).catch((error) => console.error('Error posting notes', error)).finally(() => console.log('Notes updated'));
            addExperience(350);
            invalidateCache('jam-glass-notes');
        } else {
            setCurrentNote(JAR_EMPTY_NOTE);
        }
    }

    function handleClick() {
        glassRef.current?.classList.add('heartBeat');
        pullNote();
        const newFillHeight = getFillHeight(notes, selectedJarVersion, maximumFillHeight);
        setFillHeight(newFillHeight);
        if (dialogRef.current) {
            setDialogOpen(true);
            dialogRef.current.showModal();
        }
    }

    function handleVersionChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedJarVersion(parseInt(event.target.value));
    }

    function closeDialog() {
        glassRef.current?.classList.remove('heartBeat');
        if (dialogRef.current) {
            setDialogOpen(false);
            dialogRef.current.close();
        }
    }

    async function postNote(note: Note): Promise<void> {
        try {
            const noteRef = doc(db, `jam-glass-notes-${getEnvKey('ENV')}`, note.id.toString());
            await updateDoc(noteRef, {
                pulled: note.pulled
            });
            console.log('Note updated successfully');
        } catch (error) {
            console.error('Error updating note:', error);
        }
    }

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const docRef = doc(db, `config-${getEnvKey('ENV')}`, 'jam-glass');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setJarVersion(docSnap.data().jarVersion);
                    setSelectedJarVersion(docSnap.data().jarVersion);
                    console.log('JAR VERSION:', docSnap.data().jarVersion);
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };
    
        fetchDocument();
    }, []);

    useEffect(() => {
        const handleClickAnywhere = (event: MouseEvent) => {
            if(dialogOpen) {
                event.preventDefault();
                closeDialog();
            }
        };
        document.addEventListener('mousedown', handleClickAnywhere);
        return () => {
            document.removeEventListener('mousedown', handleClickAnywhere);
        };
    }, [dialogOpen]);

    useEffect(() => {
        const fetchNotes = async (): Promise<Array<Note>> => {
            setLoading(true);
            try {
                const notesQuery = await query(collection(db, `jam-glass-notes-${getEnvKey('ENV')}`), orderBy('id'));
                const querySnapshot = await getDocs(notesQuery);
                const fetchedNotes: Array<Note> = [];
                querySnapshot.forEach((doc) => {
                    fetchedNotes.push({ id: doc.id, ...doc.data() } as unknown as Note);
                });
                
                return fetchedNotes;
            } catch (error) {
                console.error('Error fetching notes:', error);
                throw error;
            }
        };

        getCachedData('jam-glass-notes', fetchNotes)
        .then((data: Array<Note>) => {
            setNotes(data);
            console.log('Notes fetched successfully');
          })
          .catch((error: Error) => {
            console.error("Failed to retrieve rewards data:", error);
          })
          .finally(() => {
              setLoading(false);
          });
    }, []);

    useEffect(() => {
        if(notes.length){
            const newFillHeight = getFillHeight(notes, selectedJarVersion, maximumFillHeight);
            setFillHeight(newFillHeight);
        }
    }, [notes, selectedJarVersion, maximumFillHeight]);

    const glass = (
        <>
            <div ref={glassRef} className="relative h-[23.125rem] w-[17.5rem] flex justify-center" onClick={handleClick}>
                <img src={hat} alt="which-hat" className="absolute z-20 left-[12rem] top-[-4rem] rotate-[25deg]" />
                <img src={bat} alt="bat" className="absolute z-20 top-[-4rem] rotate-[-5deg] w-[8rem]" />
                <img src={pumpkin} alt="pumpkin" className="absolute z-20 right-[12rem] top-[-4rem] rotate-[-25deg]" />
                <img src={cat} alt="cat" className="absolute z-20 right-[12rem] bottom-0" />
                <img src={tree} alt="tree" className="absolute z-0 left-[10rem] bottom-0" />
                <img src={potion} alt="potion" className="absolute z-20 left-[14rem] bottom-[-1rem]" />
                <img src={glassSvg} alt="Glass" className="absolute z-10" />
                <div className="absolute flex flex-col top-[35%] font-semibold text-3xl font-glass z-20 text-white text-center">
                    <span>Jana's</span>
                    <span>Marmeladenglas</span>
                    <span>Momente</span>
                </div>
                <div className={`z-0 absolute bottom-3 w-[250px] bg-zinc-800`} style={{ height: maximumFillHeight }} />
                <div className={`z-0 absolute bottom-3 w-[250px] bg-red-700`} style={{ height: fillHeight }} />
            </div>

            <dialog ref={dialogRef} className={`p-5 bg-[#e5c7a0] min-h-6 w-[18.75rem] rounded-[1.25rem] text-center backdrop:bg-transparent focus:outline-none ${dialogOpen ? 'zoomInDown' : ''}`}>
                <span autoFocus>{currentNote}</span>
                {currentNote !== JAR_EMPTY_NOTE && <div className='mt-2 text-[#7f5a2d] text-2xl font-gaming font-bold w-full text-center'>+350 XP</div>}
            </dialog>
        </>
    );

    const collectionElement = (
        <div className="flex w-full items-center flex-col">
            { 
                notes
                .filter((note) => note.pulled && note.jarVersion === selectedJarVersion)
                .map(note => (
                    <div className="w-[18.75rem] p-1 mt-2 mb-2 first-of-type:mt-5 last-of-type:mb-5 rounded-lg bg-[#977549] text-white text-center" key={note.id}>{note.text}</div>
                ))
            }
        </div>
    )

    return (
        <div className="relative h-screen w-full bg-black">
            <ScrollingHeader className='z-30'>
                <BackButton />
            </ScrollingHeader>
            <div className={`flex justify-center items-center h-screen w-full bg-jam-glass overflow-x-hidden transition-opacity duration-700 ${dialogOpen ? 'opacity-50 delay-500' : 'opacity-100'}`}>
                {
                    loading ? <Loading loading={loading} /> : glass
                }
            </div>
            <div className='flex justify-center px-4 pt-4'>
                <select 
                    name="jarVersion"
                    value={selectedJarVersion}
                    onChange={handleVersionChange}
                    className="w-[18.75rem] bg-[#977549] text-white border border-gray-300 text-sm rounded-lg block p-2.5 "
                >
                    {Array.from({ length: jarVersion }, (_, i) => {
                        const version = i + 1;
                        return (
                        <option value={version} key={version}>
                            Jam Glass Nr. {version}
                        </option>
                        );
                    })}
                </select>
            </div>
            {loading ? <></> : collectionElement}
        </div>
    );
};

export default JamGlass;
