import React, { useState, useEffect, useRef } from 'react';

type ScrollingHeaderProps = {
    className?: string;
    children: React.ReactNode;
};

const ScrollingHeader: React.FC<ScrollingHeaderProps> = ({ children, className }) => {
    const [hideHeader, setHideHeader] = useState(false);
    const lastScrollPos = useRef(window.scrollY);
    const scrollThreshold = 50;
  
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const deltaY = currentScrollPos - lastScrollPos.current;
  
      if (currentScrollPos <= 0) {
        // At the top of the page
        setHideHeader(false);
      } else if (deltaY > 0 && currentScrollPos > scrollThreshold) {
        // Scrolling down and past the threshold
        setHideHeader(true);
      } else if (deltaY < 0) {
        // Scrolling up
        setHideHeader(false);
      }
  
      lastScrollPos.current = currentScrollPos;
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
      <div
        className={`fixed top-0 w-full transition-transform duration-300 ${
          hideHeader ? '-translate-y-full' : 'translate-y-0'
        } ${className}`}
      >
        {children}
      </div>
    );
};

export default ScrollingHeader;
