// LevelCreator.tsx
import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { db } from '../../../firebaseconfig';
import { RewardLevel } from '../rewards/reward-models';
import StageCreator from './stage-creator';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import BackButton from '../../utils/back-button/back-button';
import DoofmausSentencesForm from './doofmaus-sentence-form';


const LevelCreator: React.FC = () => {
  const [levelNumber, setLevelNumber] = useState<string>('');
  const [levelName, setLevelName] = useState<string>('');
  const [levels, setLevels] = useState<RewardLevel[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<RewardLevel | null>(null);
  const [ui, setUi] = useState<string>('Levels');

  useEffect(() => {
    fetchLevels();
    if (!levels.length) {
        setLevelNumber('1');
      }
  }, []);

  const fetchLevels = async () => {
    try {
      const levelsCollectionRef = collection(db, 'bear-rewards');
      const levelsSnapshot = await getDocs(levelsCollectionRef);
      const levelsData = levelsSnapshot.docs.map(doc => doc.data() as RewardLevel);
      setLevels(levelsData);
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };

  const createOrUpdateLevel = async () => {
    if (!levelNumber || !levelName) {
      alert('Please enter both level number and level name.');
      return;
    }

    try {
      const levelDocRef = doc(db, 'bear-rewards', `level${levelNumber}`);
      await setDoc(levelDocRef, {
        level: Number(levelNumber),
        levelName: levelName,
      });
      alert('Level saved successfully!');
      fetchLevels();
      setSelectedLevel({ level: Number(levelNumber), levelName, stages: [], stagesCount: 0 });
      setUi('Levels');
    } catch (error) {
      console.error('Error saving level:', error);
      alert('Failed to save level. Please try again.');
    }
  };

  const handleLevelSelect = (level: RewardLevel) => {
    setSelectedLevel(level);
    setLevelNumber(level.level.toString());
    setLevelName(level.levelName ?? '');
    setUi('Manage Stages');
  };

  const handleNewLevel = () => {
    setLevelNumber(selectedLevel?.level.toString() ?? '');
    setLevelName(selectedLevel?.levelName ?? '');
    setUi('Create Level');
  };

  const handleLevelChange = () => {
    setUi('Create Level');
  };

  const handleMotivation = () => {
    setUi('motivation');
  };

  const handleBack = () => {
    setUi('Levels');
    setSelectedLevel(null);
  }

  const uiLevels = (
    <div className="w-11/12">
        <h2 className="text-center text-2xl font-bold">Bärchen Belohnungen</h2>
        <p className="mt-4">Hier kannst du Belohnungen & Motivationen für dein Gemeinbär erstellen & bearbeiten.</p>
        <h3 className="text-center text-2xl mt-5 mb-4">Alle Levels</h3>
        <ul>
            {levels.map((level) => (
                <li key={level.level} onClick={() => handleLevelSelect(level)} className={`flex justify-center items-center h-12 mb-2 py-2 px-4 text-1xl font-bold text-white bg-jam-glass rounded-3xl`}>
                    Level {level.level} - {level.levelName}
                </li>
            ))}
        </ul>
        <button className="p-2 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleNewLevel()}>Neues Level erstellen</button>
        <hr className="mt-4 mb-2"/>
        <button className="p-2 mt-2 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleMotivation()}>Motivationen bearbeiten</button>
    </div>
  );

  const uiCreateLevel = (
    <>
        <div className="w-11/12">
            <h2 className={`flex justify-center items-center h-12 mb-8 p-4 text-2xl font-bold text-white bg-jam-glass rounded-3xl`}>
                Level {levelNumber} - {levelName}
            </h2>
        </div>
        <div className="flex flex-col items-left">
            <button className="p-2 mx-8 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleBack()}>Zurück</button>
            <span className="mb-2 mt-4">Level Nummer</span>
            <input
                className="text-black rounded-md p-2"
                type="number"
                value={levelNumber}
                onChange={(e) => setLevelNumber(e.target.value)}
                placeholder="Level Number"
            />
            <span className="mb-2 mt-2">Level Name</span>
            <input
            className="text-black rounded-md p-2"
                type="text"
                value={levelName}
                onChange={(e) => setLevelName(e.target.value)}
                placeholder="Level Name"
            />
            <button className="p-2 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={createOrUpdateLevel}>
                {selectedLevel ? 'Update Level' : 'Create Level'}
            </button>
        </div>
    </>
  );

  const uiManageStages = (
    <>
        <button className="p-2 mt-4 mb-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleBack()}>Zurück zu Levels</button>
        <h2 className={`flex justify-center items-center h-12 p-4 text-2xl font-bold text-white bg-jam-glass rounded-3xl`}>
            Level {levelNumber} - {levelName}
        </h2>
        <button className="p-2 mt-4 mb-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleLevelChange()}>Name und Nummer ändern</button>
      {
          selectedLevel && (
              <StageCreator levelNumber={selectedLevel.level.toString()} levelName={selectedLevel.levelName ?? ''} />
            )
      }
    </>
  );

  const uiMotivation = (
    <>
      <button className="p-2 mt-4 mb-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleBack()}>Zurück</button>
      <h2 className="text-center text-2xl font-bold">Motivationen</h2>
      <p className="mt-4 px-4">Hier kannst du die motivierenden Sätze für die Sprechblase der Doofmaus erstellen & bearbeiten. Es wird zufällig eine davon ausgewählt. Kein Druck. Ich erwarte keine, aber ich möchte das du die Möglichkeit hast, wenn dir danach ist.</p>
      <p className="mt-2 px-4">Ich habe noch positive Affirmationen für uns beide hinzugefügt die zufällig dazwischen geworfen werden. Du wirst sie wiedererkennen ❤️</p>
      <DoofmausSentencesForm />
    </>
  );

  return (
    <>
        <ScrollingHeader>
            <BackButton />
        </ScrollingHeader>
        <div className='flex flex-col items-center text-white w-full mt-16'>
            {
                ui === 'Levels' && uiLevels
            }
            {
                ui === 'Create Level' && uiCreateLevel
            }
            {
                ui === 'Manage Stages' && uiManageStages
            }
            {
                ui === 'motivation' && uiMotivation
            }
        </div>
    </>
  );
};

export default LevelCreator;
