// DoofmausSentencesForm.tsx
import React, { useEffect, useState } from 'react';
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../../firebaseconfig';

interface Sentence {
  id: string;
  sentence: string;
}

const DoofmausSentencesForm: React.FC = () => {
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [newSentence, setNewSentence] = useState('');
  const [editingSentenceId, setEditingSentenceId] = useState<string | null>(
    null
  );
  const [editingSentenceText, setEditingSentenceText] = useState('');

  useEffect(() => {
    fetchSentences();
  }, []);

  const fetchSentences = async () => {
    try {
      const sentencesDocRef = doc(db, 'activity-switch', 'sentences');
      const doofmausSentencesRef = collection(sentencesDocRef, 'Doofmaus');
      const sentencesQuery = query(doofmausSentencesRef, orderBy('sentence'));
      const snapshot = await getDocs(sentencesQuery);
      const sentencesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        sentence: doc.data().sentence as string,
      }));
      setSentences(sentencesData);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const handleAddSentence = async () => {
    if (newSentence.trim() === '') return;
    try {
      const sentencesDocRef = doc(db, 'activity-switch', 'sentences');
      const doofmausSentencesRef = collection(sentencesDocRef, 'Doofmaus');
      await addDoc(doofmausSentencesRef, {
        sentence: newSentence.trim(),
      });
      setNewSentence('');
      fetchSentences();
    } catch (error) {
      console.error('Error adding sentence:', error);
    }
  };

  const handleEditSentence = async (id: string) => {
    if (editingSentenceText.trim() === '') return;
    try {
      const sentenceDocRef = doc(
        db,
        'activity-switch',
        'sentences',
        'Doofmaus',
        id
      );
      await updateDoc(sentenceDocRef, {
        sentence: editingSentenceText.trim(),
      });
      setEditingSentenceId(null);
      setEditingSentenceText('');
      fetchSentences();
    } catch (error) {
      console.error('Error updating sentence:', error);
    }
  };

  const handleDeleteSentence = async (id: string) => {
    try {
      const sentenceDocRef = doc(
        db,
        'activity-switch',
        'sentences',
        'Doofmaus',
        id
      );
      await deleteDoc(sentenceDocRef);
      fetchSentences();
    } catch (error) {
      console.error('Error deleting sentence:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 rounded shadow">
      <h2 className="text-xl font-bold mb-4">Doofmaus Sentences</h2>
      <div className="mb-4">
        <input
          type="text"
          value={newSentence}
          onChange={(e) => setNewSentence(e.target.value)}
          placeholder="Add a new sentence"
          className="w-full px-3 py-2 border rounded text-black"
        />
        <button
          onClick={handleAddSentence}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add Sentence
        </button>
      </div>
      <ul>
        {sentences.map((sentence) => (
          <li key={sentence.id} className="mb-2">
            {editingSentenceId === sentence.id ? (
              <div>
                <input
                  type="text"
                  value={editingSentenceText}
                  onChange={(e) => setEditingSentenceText(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                />
                <div className="flex space-x-2 mt-2">
                  <button
                    onClick={() => handleEditSentence(sentence.id)}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setEditingSentenceId(null);
                      setEditingSentenceText('');
                    }}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex justify-between items-center">
                <span>{sentence.sentence}</span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setEditingSentenceId(sentence.id);
                      setEditingSentenceText(sentence.sentence);
                    }}
                    className="px-2 py-1 bg-green-700 text-white rounded hover:bg-yellow-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteSentence(sentence.id)}
                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DoofmausSentencesForm;
